import { Mocker, makeMocker } from "./mock-common";
import {
  AllContingencies,
  CAAS_STATUS,
  ContingencyType,
  RTWStatusResponse,
  ValidCandidateContingenciesResponse,
} from "../api-types/caas-api";
import { StatusEnum } from "../api-types";

export const mockCaasI9Response: Mocker<{ status: CAAS_STATUS }> = makeMocker<{ status: CAAS_STATUS }>({
  status: CAAS_STATUS.Initiated,
});

export const mockRtwStatusResponse = makeMocker<RTWStatusResponse>({
  status: "Not Started",
});

export const mockAllContingenciesResponse = makeMocker<AllContingencies>({
  [ContingencyType.AirBGC]: {
    status: StatusEnum.NOT_STARTED,
  },
  [ContingencyType.BGC]: {
    status: StatusEnum.NOT_STARTED,
  },
  [ContingencyType.WorkAuthorization]: {
    status: StatusEnum.NOT_STARTED,
    secureURL: "",
    isRemote: false,
    expiredByDayOne: false,
  },
  [ContingencyType.DrugTest]: {
    status: StatusEnum.NOT_STARTED,
  },
  [ContingencyType.RightToWork]: {
    status: StatusEnum.NOT_STARTED,
    secureURL: "",
    rightToWork: undefined,
  },
  [ContingencyType.MedicalCheck]: {
    status: StatusEnum.NOT_STARTED,
  },
  [ContingencyType.AirDrugTest]: {
    status: StatusEnum.NOT_STARTED,
  },
});

export const mockValidCandidateContingenciesResponse = makeMocker<ValidCandidateContingenciesResponse>({
  applicationId: "",
  contingencyStatusList: [],
  valid: true,
});
