import { InstantEligible, InstantIneligible, WidgetDetails } from "../api-types";
import { makeMocker, Mocker } from "./mock-common";

export const mockConnectWidgetError: Mocker<WidgetDetails> = makeMocker<WidgetDetails>({
  data: undefined,
  error: {
    cause: "NOT_FOUND",
  },
});

export const mockConnectWidgetData: Mocker<WidgetDetails> = makeMocker<WidgetDetails>({
  data: {
    connectSourceUrl: "https://example.com",
    elementId: "abc123-0000-1234321",
    snippetId: "isejraoisjnesnfaisfjasjdkfajlsflkasdf",
    encodedToken: "ier3uviesudfbgveurbvsdz=xthiuernwfe",
    agendaPublicText: "<p>TestText</p>",
    estimatedWaitTimeSecondsMaximum: 300,
    estimatedWaitTimeSecondsMinimum: 300,
  },
  error: undefined,
});

export const mockInstantIneligible: Mocker<InstantIneligible> = makeMocker<InstantIneligible>({
  status: "INELIGIBLE",
  reason: "INCOMPLETE_TASKS",
  estimatedWaitTimeSecondsMinimum: 300,
  estimatedWaitTimeSecondsMaximum: 300,
});

export const mockInstantEligible: Mocker<InstantEligible> = makeMocker<InstantEligible>({
  status: "ELIGIBLE",
  estimatedWaitTimeSecondsMinimum: 300,
  estimatedWaitTimeSecondsMaximum: 300,
});
